import styles from './styles.module.scss'

const IconoGraphy = ({ iconClass, iconColor, fontSize = 'f_24', customClass = '', title = '', onClickHandler, cssStyle={} }) => (
  <i
    title={title}
    style={cssStyle}
    className={`iconwasalt ${iconClass} ${styles[iconColor]} ${styles[customClass]} ${styles[fontSize]}`}
    onClick={(e)=>{onClickHandler && onClickHandler(e)}}
  ></i>
)

export default IconoGraphy
